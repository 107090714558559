import React, { useContext } from 'react';
import StripeCustomerContext from '../Checkout/StripeCustomerContextProvider';
import BillingAddressForm, { PageType } from '../BillingAddressForm';
import { Layout } from '../Layout';
import Section from '../Section';
import { LocaleContext } from '../ContextProvider';
import { BackLink } from '../LayoutComponents';
import { useTranslation } from 'react-i18next';

const BillingAddress = () => {
  const { t } = useTranslation(['billingAddress', 'commonLabels']);
  const { rootPath } = useContext(LocaleContext);

  return (
    <Layout
      title={`${t('title')} - CloudFit`}
      description={t('metaDescription')}
      colorTheme="light"
    >
      <Section.Container backgroundColor="light" id="billingAddress.id">
        <BackLink to={`${rootPath}account`}>
          {t('back', { ns: 'commonLabels' })}
        </BackLink>
        <StripeCustomerContext>
          <BillingAddressForm pageType={PageType.account} />
        </StripeCustomerContext>
      </Section.Container>
    </Layout>
  );
};

export default BillingAddress;
