import React from 'react';
import BillingAddress from '../../../components/Account/BillingAddress';
import ContextProvider from '../../../components/ContextProvider';
import PrivateRoute from '../../../components/PrivateRoute';

const BillingAddressPageEnAu = () => (
  <ContextProvider localeCode="en-AU">
    <PrivateRoute
      path="/en-AU/account/billing-address"
      component={BillingAddress}
    />
  </ContextProvider>
);

export default BillingAddressPageEnAu;
